import React, { useEffect } from 'react'
import { IoIosArrowBack } from 'react-icons/io'
import { useSelector } from 'react-redux'
import { Link, useLocation, useNavigate } from 'react-router-dom'

// import Img2 from "../../assets/banner/ban11.jpg"
// import Img3 from "../../assets/activity3.png"
// import Img4 from "../../assets/banner/ban13.jpg"
// import Img5 from "../../assets/banner/ban15.jpg"
// import Img6 from "../../assets/banner/ban12.jpg"
// import Img7 from "../../assets/banner/ban14.jpg"


// import Banner1 from "../../assets/banner/ban5.png"
// import Banner2 from "../../assets/banner/ban10.png"
// import Banner3 from "../../assets/banner/ban3.png"
// import Banner4 from "../../assets/banner/ban9.png"
// import Banner5 from "../../assets/banner/ban4.png"
// import Banner6 from "../../assets/banner/ban7.png"
// import Banner7 from "../../assets/banner/ban1.png"

import Img2 from "../../assets/vipdetail2.png"
import Img3 from "../../assets/activity3.png"
import Img4 from "../../assets/activity4.png"
import Img5 from "../../assets/activity5.jpg"
import Img6 from "../../assets/activity6.png"
import Img7 from "../../assets/activity7.jpg"

const Banner1 = "https://res.cloudinary.com/dx1fouxno/image/upload/v1726567633/activity-banner1_isndmj.png"
const Banner8 = "https://res.cloudinary.com/dx1fouxno/image/upload/v1726567711/activity-banner8_wn2mqt.jpg"

const Banner2 = "https://res.cloudinary.com/dx1fouxno/image/upload/v1726567632/activity-banner2_gygbi6.png"
const Banner3 = "https://res.cloudinary.com/dx1fouxno/image/upload/v1726567633/activity-banner3_wfozfd.png"
const Banner4 = "https://res.cloudinary.com/dx1fouxno/image/upload/v1726567651/activity-banner4_pnez6x.jpg"
const Banner5 = "https://res.cloudinary.com/dx1fouxno/image/upload/v1726567705/activity-banner5_loctoi.png"
const Banner6 = "https://res.cloudinary.com/dx1fouxno/image/upload/v1726567707/activity-banner6_thycov.png"
const Banner7 = "https://res.cloudinary.com/dx1fouxno/image/upload/v1726567708/activity-banner7_wcoekm.png"


// const Banner1 = "https://res.cloudinary.com/djkkjx9ry/image/upload/v1724674549/bdgclub/ban5_u5sfak.png"
// const Banner2 = "https://res.cloudinary.com/djkkjx9ry/image/upload/v1724674565/bdgclub/ban10_ugbmtx.png"
// const Banner3 = "https://res.cloudinary.com/djkkjx9ry/image/upload/v1724674567/bdgclub/ban3_ob2xkq.png"
// const Banner4 = "https://res.cloudinary.com/djkkjx9ry/image/upload/v1724674563/bdgclub/ban9_uouybj.png"
// const Banner5 = "https://res.cloudinary.com/djkkjx9ry/image/upload/v1724674553/bdgclub/ban4_i5vzxp.png"
// const Banner6 = "https://res.cloudinary.com/djkkjx9ry/image/upload/v1724674544/bdgclub/ban7_i1tr6g.png"
// const Banner7 = "https://res.cloudinary.com/djkkjx9ry/image/upload/v1724674545/bdgclub/ban1_vf1fmo.png"
const ActivityDetail = () => {
    const { userInfo } = useSelector((state) => state.auth)
    const navigate = useNavigate()
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');


    useEffect(()=>{
        window.scrollTo({top:0,behavior:"smooth"})
    },[])
    return (
        <>
            <div className='nav-bg p-1 py-3 sticky top-0 z-10'>
                <div className="container-section flex justify-between items-center">
                    <button className='absolute' onClick={() => navigate("/activity")}><IoIosArrowBack className='text-xl' /></button>
                    <h1 className='heading-h1 gray-100 text-center flex justify-center items-center m-auto'>Activity details</h1>
                </div>
            </div>
{id==2&&(
            <div>
                <img src={Banner2} alt="" className='w-full' />
                <h3 className="heading-h3 text-center mt-3 mb-1 gray-50 font-medium"> VIP Weekly Package</h3>
                <div className="container-section">
                    <img src={Img2} alt="" />

                    <p className='fs-sm text-center gray-50 mt-3'> Please click the link beloto reach our Package</p>
                    <Link to={userInfo?.telegram} className="fs-sm color-blue-500 text-center flex justify-center">{userInfo?.telegram}</Link>
                </div>
            </div>

)}
{id==3&&(
            <div>
                <img src={Banner3} alt="" className='w-full' />
                <h3 className="heading-h3 text-center mt-3 mb-1 gray-50 font-medium">💰 Member Activities Winning Streak 💰</h3>
                <div className="container-section">
                    <img src={Img3} alt="" />

                                   </div>
            </div>
)}
{id==4&&(
            <div>
                <img src={Banner4} alt="" className='w-full' />
                <h3 className="heading-h3 text-center mt-3 mb-1 gray-50 font-medium">▶️ Bharat Club Creative Video ▶️</h3>
                <div className="container-section">
                    <img src={Img4} alt="" />
  </div>
            </div>
)}
{id==5&&(
            <div>
                <img src={Banner5} alt="" className='w-full' />
                <h3 className="heading-h3 text-center mt-3 mb-1 gray-50 font-medium">🔄 Lucky "10" Days Of Interest 🔄</h3>
                <div className="container-section">
                    <img src={Img5} alt="" />
  </div>
            </div>
)}
{id==6&&(
            <div>
                <img src={Banner6} alt="" className='w-full' />
                <h3 className="heading-h3 text-center mt-3 mb-1 gray-50 font-medium">🚀 Aviator Fly High & Win Big 🚀</h3>
                <div className="container-section">
                    <img src={Img6} alt="" />
  </div>
            </div>
)}
{id==7&&(
            <div>
                <img src={Banner7} alt="" className='w-full' />
                <h3 className="heading-h3 text-center mt-3 mb-1 gray-50 font-medium">⁉️ Mysterious Gift ⁉️</h3>
                <div className="container-section">
                    <img src={Img7} alt="" />
  </div>
            </div>
)}

        </>
    )
}

export default ActivityDetail
