import React from 'react'
import Img from "../assets/loader.png"
const Loader = () => {
  return (
    <>
         <div className="loader" role="status">
     <img src={Img} alt="" />
                     
                 </div>
    </>
  )
}

export default Loader
