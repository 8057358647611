import React,{useEffect, useState} from 'react'
import "./navbar.css"
import { RiHomeSmileLine } from 'react-icons/ri'
import { MdDiamond, MdOutlineAccountBox, MdOutlineLocalActivity } from 'react-icons/md'
import { BiWallet } from 'react-icons/bi'
import { useNavigate,useLocation, Link } from 'react-router-dom'
import ServiceImg from "../assets/icon_sevice.png"
const Navbar = () => {
  const [activeItem, setActiveItem] = useState('/');
  const  naviaget=useNavigate()
  const handleClick = (item) => {
    setActiveItem(item);
    naviaget(`/${item}`)
  };
  let location = useLocation();
  useEffect(()=>{
    setActiveItem(location.pathname)
  },[activeItem])
  return (
    <>
      <div className='navbar-section'>
        <div className={`gray-100 flex  justify-center items-center flex-col p-2  ${activeItem === '/'||activeItem === '//' ? 'active' : ''}`}  onClick={() => handleClick('/')}>
          <RiHomeSmileLine className='text-2xl' />
          <span className='text-[11px] font-medium'>Home</span>
        </div>
        <div className={`gray-100 flex  justify-center items-center flex-col p-2   ${activeItem === '/activity' ? 'active' : ''} `} onClick={() => handleClick('activity')}>
          <MdOutlineLocalActivity className='text-2xl' />
          <span className='text-[11px] font-medium'>Activity</span>
        </div>
        <div className='gray-100  p-2' onClick={() => handleClick('promotion')}>
          <div className='nav-promotion'>

            <MdDiamond className='text-2xl' />
          </div>
          <span className={`text-[11px] font-medium  ${activeItem === '/promotion' ? 'active' : ''}`} >Promotion</span>
        </div>
        <div className={`gray-100 flex  justify-center items-center flex-col p-2   ${activeItem === '/wallet' ? 'active' : ''}`} onClick={() => handleClick('wallet')}>
          <BiWallet className='text-2xl' />

          <span className='text-[11px] font-medium'  >Wallet</span>
        </div>
        <div className={`gray-100 flex  justify-center items-center flex-col p-2   ${activeItem === '/main' ? 'active' : ''}`} onClick={() => handleClick('main')}>
          <MdOutlineAccountBox className='text-2xl' />
          <span className='text-[11px] font-medium'>Account</span>
        </div>



      </div>

      <div className='fixed bottom-20 right-10'>
        <Link to={"/main/CustomerService"}>
        <img src={ServiceImg} alt="" className='w-16'/>
        </Link>
      </div>
    </>
  )
}

export default Navbar
