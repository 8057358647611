import React, { useEffect, useState } from 'react';
import {  HashRouter as Router } from "react-router-dom";
import './App.css';
import AppComponent from './AppComponent';

function App() {


  return (
    <>

<Router>
<AppComponent/>

    </Router>

    </>
  );
}

export default App;
