import React from 'react'
import './Page.css'

const Loader = () => {
  return (
    <div className='h-[100vh] w-[100%] flex items-center justify-center'>
<div class="loading-bar">Loading</div>

    </div>
  )
}

export default Loader
